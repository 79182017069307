import request from "@/utils/request";

export const getUserInfo = (params: any) =>
    request({
        url: '/users/info',
        method: 'get',
        params
    });

export const login = (data: any) =>
    request({
        url: '/users/login',
        method: 'post',
        data
    });

export const logout = () =>
    request({
        url: '/users/logout',
        method: 'post'
    });

// 用户列表
export const listUsers = (params: any) =>
    request({
        url: '/users/list',
        method: 'get',
        params,
    });

// 保存一个权限分组
export const saveGroup = (data: any) =>
    request({
        url: '/users/group/save',
        method: 'post',
        data,
    });

// 查询一个权限分组信息
export const getGroup = (params: any) =>
    request({
        url: '/users/group/q',
        method: 'get',
        params,
    });

// 权限列表列表
export const listGroup = (params: any) =>
    request({
        url: '/users/group/list',
        method: 'get',
        params,
    });

// 权限内容
export const listPerms = (params: any) =>
    request({
        url: '/perms/data',
        method: 'get',
        params,
    });

// 权限筛选条件
export const basicPerms = (params: any) =>
    request({
        url: '/perms/basic',
        method: 'get',
        params,
    });

// 保存一个后台账号
export const saveUsers = (data: any) =>
    request({
        url: '/users/save',
        method: 'post',
        data,
    });

// 查看一个用户的信息
export const getUser = (params: any) =>
    request({
        url: '/users/info/q',
        method: 'get',
        params,
    });

// 保存用户设置
export const saveSettings = (data: any) =>
    request({
        url: '/user/settings',
        method: 'post',
        data,
    });
