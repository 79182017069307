import {login, logout, getUserInfo} from "@/api/users";
import {getToken, setToken, removeToken} from "@/utils/auth";

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        avatar: '',
        uid: 0,
        routers: [],
        root: false,
    }
};

const state = getDefaultState();

const mutations = {
    SET_NAME: (state, name) => {
        state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
    },
    SET_ROLES: (state, roles: string[]) => {
        state.routers = roles;
    },
    SET_UID : (state, uid: number) => {
        state.uid = uid;
    },
    SET_ROOT : (state, root: boolean) => {
        state.root = root;
    },
}

const actions = {
    // @ts-ignore
    async Login({ commit }, userInfo: { username: string, password: string }) {
        let username = userInfo.username;
        const password = userInfo.password;
        username = username.trim();

        const { data } = await login({ username, password });
        setToken(data.accessToken);
        commit('SET_TOKEN', data.accessToken);
    },
    async LogOut({ commit }) {
        if (this.token === '') {
            throw Error('LogOut: token is undefined!');
        }
        await logout();
        removeToken();
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
    },
    async GetUserInfo({ commit, state }) {
        if (state.token === '') {
            throw Error('GetUserInfo: token is undefined!');
        }
        const { data } = await getUserInfo({ /* Your params here */ })
        if (!data) {
            throw Error('Verification failed, please Login again.');
        }
        const { roles, name, avatar, id, root } = data.user;
        // roles must be a non-empty array
        /*if (!roles || roles.length <= 0) {
            throw Error('GetUserInfo: roles must be a non-null array!');
        }*/
        commit('SET_ROLES', roles);
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar);
        commit('SET_UID', id);
        commit('SET_ROOT', root);
    },
    resetToken({ commit }) {
        removeToken();
        commit('SET_TOKEN', '');
        commit('SET_ROLES', '');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
