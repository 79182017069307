import { createStore } from 'vuex'

import getters from "@/store/getters";
import user from "@/store/modules/user";


export default createStore({
  state: {
    app: {},
    // user: user,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    // @ts-ignore
    user
  },
  getters,
})
