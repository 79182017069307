import { createRouter, createWebHistory } from 'vue-router';
import AuthLayout from '@/layout/auth-layout.vue';
import AppLayout from '@/layout/app-layout.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/admin/dashboard',
    component: () => import('@/views/Home.vue'),
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('@/views/admin/dashboard/Dashboard.vue'),
        meta: {
          title: '总览',
        },
      },
      {
        name: 'Redirect',
        path: 'redirect',
        component: () => import('../views/admin/Redirect.vue'),
      },
      {
        name: 'Question',
        path: 'question',
        component: () => import('@/views/admin/question/Question.vue'),
        meta: {
          title: '题库',
        },
      },
      {
        name: 'edit',  // 题目编辑
        path: 'question/edit',
        component: () => import('@/views/admin/question/QuestionEdit.vue'),
        meta: {
          title: '题目-编辑',
        },
      },
      {
        name: 'add',  // 题目新增
        path: 'question/add',
        component: () => import('@/views/admin/question/QuestionAdd.vue'),
        meta: {
          title: '题目-新增',
        },
      },
      {
        name: 'TestPapers',
        path: 'test_papers',
        component: () => import('@/views/admin/test_papers/TestPapers.vue'),
        meta: {
          title: '试卷',
        },
      },
      {
        name: 'AddPapers',
        path: 'papers/add',
        component: () => import('@/views/admin/test_papers/PapersAdd.vue'),
        meta: {
          title: '试卷-新增',
        },
      },
      {
        name: 'EditPapers',
        path: 'papers/edit',
        component: () => import('@/views/admin/test_papers/PapersEdit.vue'),
        meta: {
          title: '试卷-编辑',
        },
      },
      {
        name: 'ViewPapers',
        path: 'papers/view',
        component: () => import('@/views/admin/test_papers/PapersView.vue'),
        meta: {
          title: '试卷-预览',
        },
      },
      {
        name: 'Article',
        path: 'article',
        component: () => import('@/views/admin/article/ArticleIndex.vue'),
        meta: {
          title: '免费资源',
        },
      },
      {
        name: 'addArticle',
        path: 'article/add',
        component: () => import('@/views/admin/article/ArticleAdd.vue'),
        meta: {
          title: '添加免费资源',
        },
      },
      {
        name: 'editArticle',
        path: 'article/edit',
        component: () => import('@/views/admin/article/ArticleEdit.vue'),
        meta: {
          title: '编辑免费资源',
        },
      },
      {
        name: 'Textbook',
        path: 'textbook',
        component: () => import('@/views/admin/textbook/Textbook.vue'),
        meta: {
          title: '教材',
        },
      },
      {
        name: 'TextbookAdd',
        path: 'textbook/add',
        component: () => import('@/views/admin/textbook/TextbookAdd.vue'),
        meta: {
          title: '添加教材',
        },
      },
      {
        name: 'TextbookEdit',
        path: 'textbook/edit',
        component: () => import('@/views/admin/textbook/TextbookEdit.vue'),
        meta: {
          title: '编辑教材',
        },
      },
      {
        name: 'Chapter',
        path: 'chapter',
        component: () => import('@/views/admin/chapter/Chapter.vue'),
        meta: {
          title: '章节',
        },
      },
      {
        name: 'ChapterViewer',
        path: 'chapter/viewer',
        component: () => import('@/views/admin/chapter/ChapterViewer.vue'),
        meta: {
          title: '章节',
        },
      },
      {
        name: 'ChapterAdd',
        path: 'chapter/add',
        component: () => import('@/views/admin/chapter/ChapterAdd.vue'),
        meta: {
          title: '新增章节',
        },
      },
      {
        name: 'ChapterEdit',
        path: 'chapter/edit',
        component: () => import('@/views/admin/chapter/ChapterEdit.vue'),
        meta: {
          title: '修改章节',
        },
      },
      {
        name: 'Subjects',
        path: 'subjects',
        component: () => import('@/views/admin/subjects/Subjects.vue'),
        meta: {
          title: '学科',
        },
      },
      {
        name: 'AddSubjects',
        path: 'subjects/add',
        component: () => import('@/views/admin/subjects/SubjectsAdd.vue'),
        meta: {
          title: '学科-新增',
        },
      },
      {
        name: 'EditSubjects',
        path: 'subjects/edit',
        component: () => import('@/views/admin/subjects/SubjectsEdit.vue'),
        meta: {
          title: '学科-编辑',
        },
      },
      {
        name: 'KnowledgePoints',
        path: 'knowledge_points',
        component: () => import('@/views/admin/knowledge_points/KnowledgePoints.vue'),
        meta: {
          title: '知识点',
        },
      },
      {
        name: 'AddKnowledgePoints',
        path: 'knowledge_points/add',
        component: () => import('@/views/admin/knowledge_points/KnowledgePointsAdd.vue'),
        meta: {
          title: '知识点-新增',
        },
      },
      {
        name: 'EditKnowledgePoints',
        path: 'knowledge_points/edit',
        component: () => import('@/views/admin/knowledge_points/KnowledgePointsEdit.vue'),
        meta: {
          title: '知识点-编辑',
        },
      },
      {
        name: 'Members',
        path: 'members',
        component: () => import('@/views/admin/members/Members.vue'),
        meta: {
          title: '用户',
        },
      },
      {
        name: 'EditMembers',
        path: 'members/edit',
        component: () => import('@/views/admin/members/MembersEdit.vue'),
        meta: {
          title: '用户-编辑',
        },
      },
      {
        name: 'ViewChildren',
        path: 'children/view',
        component: () => import('@/views/admin/members/ChildrenView.vue'),
        meta: {
          title: '小孩-预览',
        },
      },
      {
        name: 'ExamChildren',
        path: 'children/exam',
        component: () => import('@/views/admin/members/ChildrenExam.vue'),
        meta: {
          title: '小孩-考试',
        },
      },
      {
        name: 'Orders',
        path: 'orders',
        component: () => import('@/views/admin/orders/Orders.vue'),
        meta: {
          title: '订单',
        },
      },
      {
        name: 'ViewOrders',
        path: 'orders/view',
        component: () => import('@/views/admin/orders/OrdersView.vue'),
        meta: {
          title: '订单-编辑',
        },
      },
      {
        name: 'Settings',
        path: 'settings',
        component: () => import('@/views/admin/settings/Settings.vue'),
        meta: {
          title: '设置',
        },
      },
      {
        name: 'UsersIndex',  // 用户列表
        path: 'users/index',
        meta: {
          title: '后台用户',
        },
        component: () => import('../views/admin/users/UserIndex.vue'),
      },
      {
        name: 'UsersAdd',  // 增加用户
        path: 'users/add',
        meta: {
          title: '增加用户',
        },
        component: () => import('../views/admin/users/SaveUser.vue'),
      },
      {
        name: 'UsersEdit',  // 编辑用户
        path: 'users/edit',
        meta: {
          title: '编辑用户',
        },
        component: () => import('../views/admin/users/SaveUser.vue'),
      },
      {
        name: 'UsersGroup',  // 权限分组
        path: 'users/group',
        meta: {
          title: '权限分组',
        },
        component: () => import('../views/admin/users/UserGroup.vue'),
      },
      {
        name: 'AddGroup',  // 新增权限分组
        path: 'users/group/add',
        meta: {
          title: '增加权限分组',
        },
        component: () => import('../views/admin/users/SaveUserGroup.vue'),
      },
      {
        name: 'EditGroup',  // 编辑权限分组
        path: 'users/group/edit',
        meta: {
          title: '编辑权限分组',
        },
        component: () => import('../views/admin/users/SaveUserGroup.vue'),
      }
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'Login',  // 登录界面
        path: 'login',
        component: () => import('@/views/auth/login/Login.vue'),
      }
    ]
  },
  {
    name: 'QuestionView',  // 题目预览
    path: '/question/view/:id(\\d+)',
    component: () => import('@/views/admin/question/QuestionView.vue'),
    meta: {
      title: '题目-预览',
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
