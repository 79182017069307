import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import CKEditor from '@ckeditor/ckeditor5-vue';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import 'normalize.css';
import 'element-plus/dist/index.css';
import '@/styles/index.scss';
import '@/permission';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus, { locale });
app.use(CKEditor);

app.mount('#app');
